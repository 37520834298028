/* `useHardwareConcurrency` works only in the Browser environment */
var hardwareConcurrency;

if (typeof navigator !== 'undefined' && 'hardwareConcurrency' in navigator) {
  hardwareConcurrency = {
    unsupported: false,
    numberOfLogicalProcessors: navigator.hardwareConcurrency
  };
} else {
  hardwareConcurrency = {
    unsupported: true,
    numberOfLogicalProcessors: 0
  };
}

export var useHardwareConcurrency = function useHardwareConcurrency() {
  return hardwareConcurrency;
};