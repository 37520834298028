import aleksandraNik from '../images/aleksandra-nik.png';
import alexYe from '../images/alex-ye.png';
import alexanderBokhenek from '../images/alexander-bokhenek.png';
import alexanderSmimov from '../images/alexander-smimov.png';
import alexandraVachnadze from '../images/alexandra-vachnadze.png';
import andrewDurgee from '../images/andrew-durgee.jpg';
import annaLi from '../images/anna-li.png';
import antonioNamwong from '../images/antonio-namwong.jpg';
import brittCambas from '../images/britt-cambas.png';
import bryanMyint from '../images/bryan-myint.png';
import colinForsyth from '../images/colin-forsyth.png';
import darrenSandler from '../images/darren-sandler.png';
import davidShack from '../images/david-shack.png';
import eminSharifov from '../images/emin-sharifov.png';
import grahamFriedman from '../images/graham-friedman.png';
import heslinKim from '../images/heslin-kim.png';
import hosseinKhoshtaghaza from '../images/hossein-khoshtaghaza.png';
import ianGoodman from '../images/ian-goodman.png';
import jeffreyVier from '../images/jeffrey-vier.png';
import jonKnipper from '../images/jon-knipper.png';
import marcIserlis from '../images/marc-iserlis.png';
import mariaChak from '../images/maria-chak.png';
import marinaTassi from '../images/marina-tassi.png';
import markEllis from '../images/mark-ellis.png';
import mattMelbourne from '../images/matt-melbourne.png';
import mayaAloy from '../images/maya-aloy.png';
import nataliyaOliynik from '../images/nataliya-oliynik.png';
import noahThorp from '../images/noah-thorp.png';
import raymondYu from '../images/raymond-yu.png';
import ryanMcney from '../images/ryan-mcney.png';
import sarahjonReilly from '../images/sarajohn-reilly.png';
import seanRolland from '../images/sean-rolland.png';
import willWhite from '../images/will-white.png';
import zhenCao from '../images/zhen-cao.jpg';
import { E_SOCIAL_PLATFORM } from '../team-member-card/team-member-card';
// List of team member details, alphabetized by first name
export var teamMembers = {
  AleksandraNik: {
    name: 'Aleksandra Nik',
    title: 'Discovery Lead, Information Designer',
    teams: ['Advisory'],
    avatarUrl: aleksandraNik,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/coreference/'
    }],
    description: "Leads product design, systems mapping, and value flows creation. Dataviz expert, visual moderator, and researcher. Former psycholinguist, helps web3 builders devise product strategies and craft their stories."
  },
  AlexYe: {
    name: 'Alex Ye',
    title: 'Sr. Director, Research and Token Design',
    teams: ['Advisory', 'Leadership'],
    avatarUrl: alexYe,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/laoganpapi/'
    }, {
      platform: E_SOCIAL_PLATFORM.twitter,
      url: 'https://twitter.com/laoganpapi'
    }],
    description: "Leads early-stage strategy, web3 token design, and research. Formerly investor with ZZ Capital, Top Tier Capital Partners, and University of Chicago Endowment. Metaverse Georgist."
  },
  AlexanderBokhenek: {
    name: 'Alexander Bokhenek',
    title: 'Lead Blockchain Architect',
    teams: ['Advisory'],
    avatarUrl: alexanderBokhenek,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/alexander-bokhenek-9701a2176/'
    }],
    description: "Leads R/Crypto token design modeling and tech diligence. Veteran researcher of consensus mechanisms, DLT\n      protocols and their applications. Passionate about DeFi and mechanism design."
  },
  AlexanderSmimov: {
    name: 'Alexander Smimov',
    title: 'Blockchain Architect',
    teams: ['Advisory'],
    avatarUrl: alexanderSmimov,
    socialLinks: [],
    description: "Focuses on building and analyzing DeFi apps and dApp architectures with R/Crypto. Former software\n      engineer at Yandex. Decentralized maximalist with a background in mathematics and economics."
  },
  AlexandraVachnadze: {
    name: 'Alexandra Vachnadze',
    title: 'Product Lead',
    teams: ['Product'],
    avatarUrl: alexandraVachnadze,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/vachnadze/'
    }],
    description: "Drives product development for R/Crypto. A true believer in web3's future, with a deep entrepreneurial\n      background. Propagates the convergence of disciplines as a keystone of disruptive innovations."
  },
  AndrewDurgee: {
    name: 'Andrew Durgee',
    title: 'Head of Republic Crypto',
    teams: ['Leadership'],
    avatarUrl: andrewDurgee,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/andrew-durgee-b9367018'
    }],
    description: "Leads R/Crypto. Formerly a partner at blockchain investment and advisory firm TLDR. Entered crypto in\n      2010, pioneering an industry-first multi-sig wallet repository."
  },
  AnnaLi: {
    name: 'Anna Li',
    title: 'Token Design & Financial Modeling Lead',
    teams: ['Advisory'],
    avatarUrl: annaLi,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/anna-m-li/'
    }, {
      platform: E_SOCIAL_PLATFORM.twitter,
      url: 'https://twitter.com/annalimeng'
    }],
    description: "Drives token strategy for R/Crypto clients. Focused on corporate DAO governance and treasury management across GameFi and DeFi. Wharton MBA with institutional investment experience."
  },
  AntonioNamwong: {
    name: 'Antonio Namwong',
    title: 'Senior Legal Assistant',
    teams: ['Operations'],
    avatarUrl: antonioNamwong,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/antonio-namwong'
    }],
    description: "Senior legal administrator at R/Crypto. Manages legal matters for token offerings on Republic.\n      Served in the US Army, and has experience in the federal and fintech sectors."
  },
  BrittCambas: {
    name: 'Britt Cambas',
    title: 'Director',
    teams: [],
    avatarUrl: brittCambas,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/brittcambas/'
    }],
    description: "Leads culture tokenization and new products at R/Crypto. Moved into early-stage technology investing\n      and strategic operations after investing with private equity firm Carlyle, and earning Wharton MBA."
  },
  BryanMyint: {
    name: 'Bryan Myint',
    title: 'Sr. Director, Advisory',
    teams: ['Leadership', 'Advisory'],
    avatarUrl: bryanMyint,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/bryanmyint/'
    }, {
      platform: E_SOCIAL_PLATFORM.twitter,
      url: 'https://twitter.com/0xbryan_'
    }],
    description: "Co-founded R/Crypto and leads its advisory practice. Previously a Fortune 100 strategy consultant and\n      biomedical engineer. Crypto researcher, miner, and investor since 2016."
  },
  ColinForsyth: {
    name: 'Colin Forsyth',
    title: 'Creative Director',
    teams: ['Marketing'],
    avatarUrl: colinForsyth,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/colinforsyth/'
    }],
    description: "Leads creative direction at R/Crypto. Award-winning designer focused on exploring the intersection of\n      arts and tech, building elite teams, and delivering strategic, eyeball-worthy work."
  },
  DarrenSandler: {
    name: 'Darren Sandler',
    title: 'Lead Counsel',
    teams: ['Operations'],
    avatarUrl: darrenSandler,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/darrensandler/'
    }],
    description: "Lead counsel at R/Crypto. Oversees legal matters for token offerings on Republic. Previously head of\n      legal at two web3 startups, and served as attorney at Kirkland & Ellis LLP."
  },
  DavidShack: {
    name: 'David Shack',
    title: 'Sr. Director, Marketing',
    teams: ['Marketing'],
    avatarUrl: davidShack,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/david-shack/'
    }, {
      platform: E_SOCIAL_PLATFORM.twitter,
      url: 'https://twitter.com/shackamoto'
    }],
    description: "Leads R/Crypto marketing. Experienced brand builder, early and growth stage operator, with 10+ years\n      leading marketing teams at venture-backed fintech, communications, and gaming companies."
  },
  EddyWanny: {
    name: 'Eddy Wanny',
    title: 'Lead Engineer',
    teams: ['Engineering'],
    avatarUrl: null,
    socialLinks: [],
    description: "Leads advisory engineering and wallet infrastructure at R/Crypto. Previously engineering at\n      TrustWallet, Binance, and Crypto.com. Currently an active open source contributor to several DeFi projects."
  },
  EminSharifov: {
    name: 'Emin Sharifov',
    title: 'Sr. Technical Program Manager',
    teams: ['Advisory', 'Engineering'],
    avatarUrl: eminSharifov,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/emin-sharifov/'
    }],
    description: "Leads cross organizational technical programs within R/Crypto tokenization and internal products.\n      Previously Lead Security programs at BitMEX, with experience leading large product teams at Expedia."
  },
  GrahamFriedman: {
    name: 'Graham Friedman',
    title: 'Sr. Director, Venture',
    teams: ['Leadership'],
    avatarUrl: grahamFriedman,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/graham-friedman-92581314/'
    }, {
      platform: E_SOCIAL_PLATFORM.twitter,
      url: 'https://twitter.com/Shenanigrahams'
    }],
    description: "Leads investment strategy. Co-founded blockchain investment and advisory firm TLDR. Previously\n      photography director at Vaynermedia. Early Bitcoiner with a global crypto network."
  },
  HosseinKhoshtaghaza: {
    name: 'Hossein Khoshtaghaza',
    title: 'Director of Infra Engineering, Asia',
    teams: ['Infrastructure'],
    avatarUrl: hosseinKhoshtaghaza,
    socialLinks: [],
    description: "Manages R/Crypto infrastructure engineering. Full-stack engineer with 5+ years experience deploying\n      and maintaining high-availability blockchain infrastructure for public facing services."
  },
  IanGoodman: {
    name: 'Ian Goodman',
    title: 'Sr. Director, Strategy & Finance',
    teams: ['Operations'],
    avatarUrl: ianGoodman,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/iangoodman/'
    }, {
      platform: E_SOCIAL_PLATFORM.twitter,
      url: 'https://twitter.com/iangoodman'
    }],
    description: "Leads strategy and finance for R/Crypto. Previously at global investment business Blackstone, leading\n      strategy within the firm\u2019s special situations investment group."
  },
  JeffreyVier: {
    name: 'Jeffrey Vier',
    title: 'Sr. Director, Tokenization',
    teams: ['Advisory'],
    avatarUrl: jeffreyVier,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/vierjeffrey/'
    }, {
      platform: E_SOCIAL_PLATFORM.twitter,
      url: 'https://twitter.com/jeffreysherwood'
    }],
    description: "Manages client strategy and new business for R/Crypto. Previously oversaw listings for AscendEX and\n      sales for Ledger Enterprise. Has a deep understanding of DeFi, CeFi, and market microstructures."
  },
  JonKnipper: {
    name: 'Jon Knipper',
    title: 'Sr. Director, Risk & Treasury Management',
    teams: ['Asset Management'],
    avatarUrl: jonKnipper,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/jonathan-knipper-8263038/'
    }, {
      platform: E_SOCIAL_PLATFORM.twitter,
      url: 'https://twitter.com/MacroKninja'
    }],
    description: "Leads R/Crypto portfolio and liquid asset management strategies. Previously co-founded blockchain\n      investment and advisory firm TLDR in 2016. Formerly macro sales at Goldman Sachs and Morgan Stanley."
  },
  HeslinKim: {
    name: 'Heslin Kim',
    title: 'Head of Growth & Ecosystem',
    teams: ['Infrastructure'],
    avatarUrl: heslinKim,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/heslinkim/'
    }],
    description: "Formerly co-founder of SupraOracles, and VP at Euronext, Polymath, and Tokeny Solutions. Has made 140+ angel investments, raised $30M+ for projects, and serviced $28B+ in tokenized assets."
  },
  MarcIserlis: {
    name: 'Marc Iserlis',
    title: 'Director of Film',
    teams: ['Token Offerings'],
    avatarUrl: marcIserlis,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/marc-iserlis-02848a13/'
    }],
    description: "Leads new vertical at R/Crypto to finance films through token offerings. Previously a strategist with\n      72andSunny, Droga5, and Ogilvy. Currently a global film producer with Third Culture Collective."
  },
  MariaChak: {
    name: 'Maria Chak',
    title: 'Account Manager',
    teams: ['Advisory'],
    avatarUrl: mariaChak,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/maria-chak/'
    }, {
      platform: E_SOCIAL_PLATFORM.twitter,
      url: 'https://twitter.com/chakkkattack'
    }],
    description: "Manages gaming and metaverse clients at R/Crypto. Previously co-founded the strategy team at Onyx, JP\n      Morgan\u2019s blockchain business unit, focusing on innovative payment applications."
  },
  MarinaTassi: {
    name: 'Marina Tassi',
    title: 'Principal Product Manager',
    teams: ['Product'],
    avatarUrl: marinaTassi,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/marinatassi/'
    }],
    description: "Leads wallet products for R/Crypto. Previously a digital consultant helping evolve product strategy\n      for various enterprises and startups. Now focused on bridging web2 and web3."
  },
  MarkEllis: {
    name: 'Mark Ellis',
    title: 'Solutions Architect',
    teams: ['Advisory'],
    avatarUrl: markEllis,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/mark-ellis-2b9ba3230/'
    }],
    description: "Blockchain solutions architect with R/Crypto. Previously a software developer and creator of the\n      \"Infinite Wallet\". Extensive experience in wallet integration and gas optimization in smart contract deployment."
  },
  MattMelbourne: {
    name: 'Matt Melbourne',
    title: 'Sr. Director, Token Offerings',
    teams: ['Token Offerings'],
    avatarUrl: mattMelbourne,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/mattjmelbourne'
    }, {
      platform: E_SOCIAL_PLATFORM.twitter,
      url: 'https://twitter.com/mattjmelbourne'
    }],
    description: "Leads token offering strategy on Republic. Early employee who previously co-managed several entities\n      under the Republic retail platform. Focuses on applying crypto tech to all kinds of digital offerings."
  },
  MayaAloy: {
    name: 'Maya Aloy',
    title: 'Account Manager',
    teams: ['Advisory'],
    avatarUrl: mayaAloy,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/maya-aloy/'
    }, {
      platform: E_SOCIAL_PLATFORM.twitter,
      url: 'https://twitter.com/mayaaloy'
    }],
    description: "Manages clients and drives new business at R/Crypto. Previously advised and launched security token\n      offerings, primarily focusing on government entities and LatAm clients."
  },
  NataliyaOliynik: {
    name: 'Nataliya Oliynik',
    title: 'Manager, Accounting & Operations',
    teams: ['Asset Management', 'Operations'],
    avatarUrl: nataliyaOliynik,
    socialLinks: [],
    description: "Supports finance and accounting for R/Crypto asset management. Previously a financial professional\n      in the commodities trading industry, with several years of crypt industry finance experience."
  },
  NoahThorp: {
    name: 'Noah Thorp',
    title: 'Advisor',
    teams: ['Advisory', 'Engineering'],
    avatarUrl: noahThorp,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/noahthorp/'
    }],
    description: "Leads engineering strategy at R/Crypto. Serves as founder and CEO of Upside, a platform for launching\n      and managing tokens. 20+ years experience as a developer, product manager, and platform creator."
  },
  OlivierEsuka: {
    name: 'Olivier Esuka',
    title: 'Blockchain Engineer',
    teams: ['Engineering'],
    avatarUrl: null,
    socialLinks: [],
    description: "Blockchain engineer with R/Crypto. Currently focused on building non-custodial wallet. 9+ years\n      professional experience as a software engineer, building user-facing fintech products."
  },
  RaymondYu: {
    name: 'Raymond Yu',
    title: 'Manager, Token Offerings & Advisory',
    teams: ['Advisory', 'Token Offerings'],
    avatarUrl: raymondYu,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/raymondgyu/'
    }, {
      platform: E_SOCIAL_PLATFORM.twitter,
      url: 'https://twitter.com/westdayev3r'
    }],
    description: "Leads marketing for Republic token offerings, and advises R/Crypto clients on NFT strategy and\n      growth. Previously served as a Fortune 500 data consultant."
  },
  RyanMcNey: {
    name: 'Ryan McNey',
    title: 'Technical Project Manager',
    teams: ['Advisory'],
    avatarUrl: ryanMcney,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/ryanmcney/'
    }, {
      platform: E_SOCIAL_PLATFORM.twitter,
      url: 'https://twitter.com/ryanmcney'
    }],
    description: "Advises R/Crypto tokenization projects. Currently Founder of web3 community project W3DC. Previously\n      a digital product leader and brand strategist with Mobomo and Accenture."
  },
  SarahjonReilly: {
    name: 'Sarahjon Reilly',
    title: 'Sr. Director, Fund Development',
    teams: ['Asset Management'],
    avatarUrl: sarahjonReilly,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/sarahjon-reilly-76b1718/'
    }, {
      platform: E_SOCIAL_PLATFORM.twitter,
      url: 'https://twitter.com/Say1824'
    }],
    description: "Leads fund development at R/Crypto. Previously investment banking at Bank of America Securities and\n      investor relations at asset management firms. University of Chicago Booth MBA."
  },
  SeanRolland: {
    name: 'Sean Rolland',
    title: 'Sr. Director, Product',
    teams: ['Product'],
    avatarUrl: seanRolland,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/sean-r-490a1519/'
    }],
    description: "Leads R/Crypto product. Has 10+ years experience in fintech and crypto, leading teams at BitPay,\n      Kabbage, and Cardlytics. Believes every asset in the world will eventually be on-chain."
  },
  StephenRoss: {
    name: 'Stephen Ross',
    title: 'Director of Infra Engineering, Europe',
    teams: ['Infrastructure'],
    avatarUrl: null,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/stephen-ross-b2510123/'
    }],
    description: "Manages R/Crypto infrastructure engineering. Infrastructure specialist with 20+ years experience in\n      design, deployment and management of global-scale infrastructure at Nokia, Cisco and others."
  },
  WillWhite: {
    name: 'Will White',
    title: 'Director of Infra Engineering, Americas',
    teams: ['Infrastructure'],
    avatarUrl: willWhite,
    socialLinks: [],
    description: "Manages R/Crypto infrastructure engineering. SDLC optimization specialist with 20+ years of\n      experience spanning infrastructure and development in large scale distributed systems."
  },
  ZhenCao: {
    name: 'Zhen Cao',
    title: 'Advisor',
    teams: ['Advisory'],
    avatarUrl: zhenCao,
    socialLinks: [{
      platform: E_SOCIAL_PLATFORM.linkedIn,
      url: 'https://www.linkedin.com/in/zhen-cao/'
    }, {
      platform: E_SOCIAL_PLATFORM.twitter,
      url: 'https://twitter.com/gemboomboom'
    }],
    description: "Leads Asia investment opportunities for R/Crypto. Previously a crypto-focused partner at JD Capital, $9B Chinese conglomerate. Early supporter of OpenSea, AVAX, Flow, PFP and more."
  }
};