import Avalanche from './images/avalanche.jpg';
import Cere from './images/cere.jpg';
import Chingari from './images/chingari.png';
import DappRadar from './images/dappradar.png';
import Voyager from './images/voyager.png';
import ZedRun from './images/zed-run.png';
export var projectData = [{
  id: 'avalanche',
  companyName: 'Avalanche',
  title: 'We powered the rapid ascent of Avalanche, culminating in a $42 million public token offering.',
  descriptionItems: ['token design and technical documentation for $AVAX', 'led private capital raise with participation from Polychain and a16z', 'orchestrated public offering on Tokensoft, raising $42M in 5 hours', 'facilitated $AVAX listing on Binance post-offering'],
  image: Avalanche,
  subtitle: 'Avalanche is one of the fastest smart contracts platforms in web3, as measured by time-to-finality.',
  tags: ['Layer 1']
}, {
  id: 'chingari',
  companyName: 'Chingari',
  title: 'We helped Chingari get initial listings on the most Tier-1 exchanges in history.',
  descriptionItems: ['designed $GARI Social token for Chingari', "organized private fundraise roadshow for introductions to Republic Crypto's investor network", 'built custom smart contracts for $GARI DAO developed on Solana', 'chingari ran public token offering on Republic platform, raising $12 million'],
  image: Chingari,
  subtitle: 'Chingari is INDIa’s most popular social video and commerce platform, with Over 70 Million users.',
  tags: ['Entertainment', 'DAO']
}, {
  id: 'dappradar',
  companyName: 'DappRadar',
  title: 'We helped DappRadar reactivate millions of users and revive a legacy crypto brand.',
  descriptionItems: ['helped design the $RADAR token utility and token design', 'led tokenization, engineering and deploying $RADAR tokens to the Ethereum blockchain', 'created the DappRadar DAO tech stack and treasury structure', 'consulted on liquidity provision rewards campaign on SushiSwap', 'developed LP tokens and staking smart contracts for $RADAR rewards', 'designed novel airdrop strategy that saved DappRadar users $1 million in Ethereum gas fees'],
  image: DappRadar,
  subtitle: 'Dappradar is a capital-efficient, highly liquid defi protocol with fully decentralized social trading.',
  tags: ['DeFi', 'DAO']
}, {
  id: 'voyager',
  companyName: 'Voyager',
  title: 'We built the network and sales strategy that fueled a 30x increase to Voyager’s valuation.',
  descriptionItems: ['guided valuation and sales strategy, leading to a $3M private raise', 'leveraged ecosystem relationships to list $ALGO, $AVAX, $KNC, $OCEAN, and $STMX on Voyager', 'redesigned the $VGX native utility token via novel token merger', 'advised Voyager through valuation growth from $35M to $1B+ on Toronto Stock Exchange (from March 2020 to January 2021)'],
  disclaimerText: '30x valuation increase references data from $VYGVF on the Toronto Stock Exchange from March 2020 to January 2021',
  image: Voyager,
  subtitle: 'Voyager is a market-leading crypto-asset platform for trading, staking, and investing.',
  tags: ['DeFi']
}, {
  id: 'cere',
  companyName: 'Cere',
  title: 'We engineered Cere’s astonishing growth from seed round to $26 million token offering.',
  descriptionItems: ['supported Cere’s token design strategy', 'wrote ERC-20 smart contracts for token vesting and distribution', 'provided marketing support and community outreach by key opinion leaders and influencers', 'advised on concurrent Reg D 506(c) and Reg S offerings, reaching $26 million goal in under one hour'],
  image: Cere,
  subtitle: 'Cere is a cRM blockchain ecosystem in a decentralized data cloud, Optimized for collaboration.',
  tags: ['Layer 1', 'Layer 2']
}, {
  id: 'Zed Run',
  companyName: 'Zed Run',
  title: 'We helped transform the play-and-earn token economy for a global gaming phenomenon.',
  descriptionItems: ['token design and technical documentation for $ZED, the native utility token powering the Zed Run gaming ecosystem', 'guided centralized and decentralized exchange strategy prior to token launch', 'advised on technical design for product integrations and blockchain strategy', 'engineered and deployed smart contracts for $ZED token'],
  image: ZedRun,
  subtitle: 'Zed Run lets owners of digital racehorse NFTs engage in skill-based gameplay.',
  tags: ['Gaming', 'NFTs']
}];