import { Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import {
  ContactModal,
  Footer,
  HeroSection,
  OurServices,
  OurTeam,
  Partners,
  ProjectsSection,
  variants,
} from '@r-client/republic-crypto/feature/home';
import { useAppConfig } from '@r-client/shared/util/core';

export function Index() {
  const { googleRecaptchaSiteKey } = useAppConfig();
  return (
    <>
      <motion.main
        variants={variants}
        initial="hidden"
        animate="enter"
        exit="exit"
        transition={{ type: 'tween' }}
      >
        <Flex direction="column" bg="black" overflowX={{ base: 'hidden' }}>
          <HeroSection />
          <OurServices />
          <Partners />
          <OurTeam />
          <ProjectsSection />
          <Footer />
        </Flex>
      </motion.main>
      <ContactModal googleRecaptchaSiteKey={googleRecaptchaSiteKey} />
    </>
  );
}

export default Index;
