import Aglet from './images/aglet.svg';
import Althea from './images/althea.svg';
import Arcana from './images/arcana.svg';
import Avalanche from './images/avalanche.svg';
import Blockswap from './images/blockswap.svg';
import Cere from './images/cere.svg';
import Chingari from './images/chingari.svg';
import DappaRadar from './images/dappa-radar.svg';
import Delysium from './images/delysium.svg';
import Everyrealm from './images/everyrealm.svg';
import FreshCut from './images/fresh-cut.svg';
import GreenPark from './images/greenpark.svg';
import Immersed from './images/immersed.svg';
import InkFinance from './images/ink-finance.svg';
import Menthol from './images/menthol.svg';
import MetaGuild from './images/metaguild.svg';
import MirrorWorld from './images/mirror-world.svg';
import Nested from './images/nested.svg';
import Nibiru from './images/nibiru.svg';
import Nodle from './images/nodle.svg';
import PlanetMojo from './images/planetmojo.svg';
import Pokt from './images/pokt.svg';
import Ratio from './images/ratio.svg';
import Rush from './images/rush.svg';
import Secret from './images/secret-logo.svg';
import StampedeVentures from './images/stampede-ventures.svg';
import StarAtlas from './images/star-atlas.svg';
import SupraOracles from './images/supraoracles.svg';
import Zebec from './images/zebec.svg';
import Zed from './images/zed.svg';
export var PARTNERS_LIST = [{
  icon: Ratio,
  href: 'https://ratio.finance/'
}, {
  icon: Cere,
  href: 'https://cere.network/'
}, {
  icon: Avalanche,
  href: 'https://www.avax.network/'
}, {
  icon: MirrorWorld,
  href: 'https://www.mirrorworld.fun/'
}, {
  icon: DappaRadar,
  href: 'https://dappradar.com/'
}, {
  icon: FreshCut,
  href: 'https://freshcut.gg/'
}, {
  icon: Secret,
  href: 'https://scrt.network/'
}, {
  icon: SupraOracles,
  href: 'https://supraoracles.com/'
}, {
  icon: StarAtlas,
  href: 'https://staratlas.com'
}, {
  icon: Zebec,
  href: 'https://zebec.io/'
}, {
  icon: Nested,
  href: 'https://nested.fi/'
}, {
  icon: Pokt,
  href: 'https://www.pokt.network/'
}, {
  icon: Althea,
  href: 'https://www.althea.net/'
}, {
  icon: Chingari,
  href: 'https://chingari.io'
}, {
  icon: PlanetMojo,
  href: 'https://www.planetmojo.io/'
}, {
  icon: Menthol,
  href: 'https://www.mentholprotocol.com/'
}, {
  icon: Rush,
  href: 'https://rush.network/'
}, {
  icon: Arcana,
  href: 'https://arcana.network/'
}, {
  icon: Zed,
  href: 'https://zed.run/'
}, {
  icon: InkFinance,
  href: 'https://inkfinance.xyz/'
}, {
  icon: GreenPark,
  href: 'https://greenparksports.com/'
}, {
  icon: Nodle,
  href: 'https://www.nodle.com/'
}, {
  icon: Delysium,
  href: 'https://www.delysium.com/'
}, {
  icon: MetaGuild,
  href: 'https://metaguild.com/'
}, {
  icon: Aglet,
  href: 'https://aglet.app/'
}, {
  icon: StampedeVentures,
  href: 'https://stampedeventures.com/'
}, {
  icon: Blockswap,
  href: 'https://www.blockswap.network/'
}, {
  icon: Everyrealm,
  href: 'https://everyrealm.com/'
}, {
  icon: Immersed,
  href: 'https://immersed.com/'
}, {
  icon: Nibiru,
  href: 'https://nibiru.fi/'
}];