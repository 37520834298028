import { teamMembers } from './team-members';
export var teamMemberLookup = function teamMemberLookup(name) {
  // Lookup key is first and last name with no spaces
  var lookupKey = name.replace(/\s+/g, '');
  return teamMembers[lookupKey];
};
export var teamPlacementList = {
  Leadership: ['Andrew Durgee', 'Bryan Myint', 'Graham Friedman', 'Alex Ye'],
  Advisory: ['Jeffrey Vier', 'Maya Aloy', 'Maria Chak', 'Alexander Bokhenek', 'Anna Li', 'Aleksandra Nik', 'Alexander Smimov', 'Ryan McNey', 'Mark Ellis', 'Emin Sharifov', 'Noah Thorp', 'Raymond Yu', 'Zhen Cao'],
  'Asset Management': ['Jon Knipper', 'Sarahjon Reilly', 'Nataliya Oliynik'],
  Engineering: ['Noah Thorp', 'Emin Sharifov', 'Olivier Esuka', 'Eddy Wanny'],
  Infrastructure: ['Heslin Kim', 'Will White', 'Hossein Khoshtaghaza', 'Stephen Ross'],
  // Marketing: ['David Shack', 'Colin Forsyth'], // Requested to be hidden, not deleted.
  Operations: ['Darren Sandler', 'Ian Goodman', 'Antonio Namwong', 'Nataliya Oliynik'],
  Product: ['Sean Rolland', 'Marina Tassi', 'Alexandra Vachnadze'],
  'Token Offerings': ['Matt Melbourne', 'Marc Iserlis', 'Raymond Yu']
};